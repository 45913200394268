// components/Header.js
import React, { useState, useEffect } from 'react';
import { WalletButton } from './walletButton';
import { Link } from 'react-router-dom';

export const Header = ({ walletAddress, isConnecting, connectWallet, disconnectWallet }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 w-full z-50 bg-black/30 backdrop-blur-md border-b border-white/10 
      ${isMobile && isMenuOpen ? 'h-full' : ''}`}>
      {isMobile ? (
        // Mobile version
        <div className={`transition-all duration-300 ease-in-out ${isMenuOpen ? 'h-full' : 'h-16'}`}>
          <div className="flex flex-col h-full">
            {/* Top bar with logo and toggle button */}
            <div className="flex justify-between items-center px-6 py-4">
              <Link to="/agents" className="text-xl font-rajdhani font-bold text-white">
                APOLLO AGENTS
              </Link>
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 hover:bg-white/10 rounded-lg transition-colors"
              >
                {isMenuOpen ? (
                  // Close icon
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  // Menu icon
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>

            {/* Full menu content - only shown when isMenuOpen is true */}
            {isMenuOpen && (
              <div className="flex-1 flex flex-col p-6 pt-0">
                {/* Helmet Image */}
                <div className="flex justify-center">
                  <img 
                    src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/helmet.png"
                    alt="Apollo Helmet"
                    className="w-[300px] object-contain"
                  />
                </div>
                
                {/* Navigation buttons */}
                <div className="flex-1 flex flex-col justify-center space-y-6">
                  <Link 
                    to="/agents/create"
                    className="w-full py-4 bg-black/40 hover:bg-black/60 text-white text-lg font-medium 
                             rounded-lg transition-all duration-200 text-center border border-white/10
                             hover:border-white/30 backdrop-blur-sm"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Create Agent
                  </Link>
                  <Link 
                    to="/agents/dashboard"
                    className="w-full py-4 bg-black/40 hover:bg-black/60 text-white text-lg font-medium 
                             rounded-lg transition-all duration-200 text-center border border-white/10
                             hover:border-white/30 backdrop-blur-sm"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Dashboard
                  </Link>
                  <a 
                    href="https://t.me/apollo_defi"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full py-4 bg-black/40 hover:bg-black/60 text-white text-lg font-medium 
                             rounded-lg transition-all duration-200 text-center border border-white/10
                             hover:border-white/30 backdrop-blur-sm"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Telegram
                  </a>
                  <Link 
                    to="/agents/documentation"
                    className="w-full py-4 bg-black/40 hover:bg-black/60 text-white text-lg font-medium 
                             rounded-lg transition-all duration-200 text-center border border-white/10
                             hover:border-white/30 backdrop-blur-sm"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Documentation
                  </Link>
                </div>

                {/* Wallet button */}
                <div className="flex justify-center pt-2">
                  <WalletButton 
                    walletAddress={walletAddress}
                    isConnecting={isConnecting}
                    onConnect={connectWallet}
                    onDisconnect={disconnectWallet}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Desktop version - Original layout restored
        <div className="flex items-center justify-between px-8 py-4">
          {/* Logo - Fixed width */}
          <Link to="/" className="flex items-center space-x-2 w-[140px] hover:opacity-80">
            <div className="h-8 w-8 bg-black rounded-full flex items-center justify-center">
              <img 
                src="/images/logo.png" 
                alt="Apollo Logo" 
                className="h-6 w-6 object-contain"
              />
            </div>
            <span className="text-xl font-bold text-white">APOLLO</span>
          </Link>

          {/* Navigation - Takes remaining space with fixed minimum spacing */}
          <nav className="flex-1 flex items-center justify-center mx-8">
            <div className="flex items-center space-x-8">
              <Link 
                to="/agents/create"
                className="px-4 py-2 bg-black text-white rounded-md 
                         hover:bg-white hover:text-black transition-colors duration-200 
                         active:bg-gray-200 active:text-black whitespace-nowrap"
              >
                Create
              </Link>
              <Link 
                to="/agents/dashboard" 
                className="px-4 py-2 bg-black text-white rounded-md 
                         hover:bg-white hover:text-black transition-colors duration-200 
                         active:bg-gray-200 active:text-black whitespace-nowrap"
              >
                Dashboard
              </Link>
              <a 
                href="https://t.me/apollo_defi" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-4 py-2 bg-black text-white rounded-md 
                         hover:bg-white hover:text-black transition-colors duration-200 
                         active:bg-gray-200 active:text-black whitespace-nowrap"
              >
                Telegram
              </a>
              <Link 
                to="/agents/documentation" 
                className="px-4 py-2 bg-black text-white rounded-md 
                         hover:bg-white hover:text-black transition-colors duration-200 
                         active:bg-gray-200 active:text-black whitespace-nowrap"
              >
                Documentation
              </Link>
            </div>
          </nav>
          
          {/* Wallet Button - Fixed width */}
          <div className="w-[140px]">
            <WalletButton 
              walletAddress={walletAddress}
              isConnecting={isConnecting}
              onConnect={connectWallet}
              onDisconnect={disconnectWallet}
            />
          </div>
        </div>
      )}
    </header>
  );
};