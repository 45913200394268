import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div className="absolute inset-0 bg-black/70 backdrop-blur-sm" onClick={onClose}></div>
      
      {/* Dialog */}
      <div className="relative z-10 bg-gray-800/90 backdrop-blur-lg border border-gray-700 rounded-lg p-6 max-w-md w-full mx-4 shadow-2xl">
        <h3 className="text-xl font-semibold text-white mb-4">{title}</h3>
        <p className="text-gray-300 mb-6">{message}</p>
        
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-md transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-md transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditAgent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = usePrivy();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agent, setAgent] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [hasChanges, setHasChanges] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogConfig, setConfirmDialogConfig] = useState({
    title: '',
    message: '',
    onConfirm: () => {},
    field: ''
  });

  const getFieldOrder = (fields) => {
    const order = [
      // Personality/Character fields
      'name',
      'bio',
      'lore',
      'system',
      'adjectives',
      'topics',
      'knowledge',
      'messageExamples',
      'postExamples',
      'style',
      // Technical fields
      'modelProvider',
      'settings',
      'plugins',
      'clientSettings',
      'status'
    ];

    // Sort fields according to our order, putting any undefined fields at the end
    return Object.entries(fields).sort(([a], [b]) => {
      const aIndex = order.indexOf(a);
      const bIndex = order.indexOf(b);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgent = async () => {
      if (!user?.id || !id) {
        console.log('Missing required data:', { userId: user?.id, agentId: id });
        if (mounted) {
          setError('Please connect your wallet to view this agent');
        }
        return;
      }

      try {
        // First check if the user has permission to view this agent
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/agents/${id}/`, {
          headers: {
            'uid': user.id
          }
        });
        
        if (!mounted) return;

        if (!response.data) {
          throw new Error('No data received from server');
        }

        const parsedData = typeof response.data.character_json === 'string' 
          ? JSON.parse(response.data.character_json)
          : response.data.character_json;

        if (parsedData.privyId !== user.id) {
          if (mounted) {
            setError('You do not have permission to view or edit this agent');
          }
          return;
        }

        // Only show loading after we confirm the user has permission
        if (mounted) {
          setLoading(true);
        }

        if (mounted) {
          setAgent(parsedData);
          setEditedValues(parsedData);
        }
      } catch (err) {
        console.error('Error details:', err);
        if (mounted) {
          const errorMessage = err.response?.status === 403 
            ? 'You do not have permission to view or edit this agent'
            : err.response?.data?.message || err.message || 'Failed to load agent details';
          setError(errorMessage);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchAgent();

    return () => {
      mounted = false;
    };
  }, [id, user, navigate]);

  const handleEdit = (field) => {
    setEditMode(prev => ({ ...prev, [field]: true }));
  };

  const handleSave = (field) => {
    setEditMode(prev => ({ ...prev, [field]: false }));
    setAgent(prev => ({ ...prev, [field]: editedValues[field] }));
    setHasChanges(prev => ({ ...prev, [field]: false }));
  };

  const handleCancelEdit = (field) => {
    if (hasChanges[field]) {
      setConfirmDialogConfig({
        title: 'Discard Changes?',
        message: 'You have unsaved changes. Are you sure you want to discard them?',
        onConfirm: () => {
          setEditedValues(prev => ({
            ...prev,
            [field]: agent[field]
          }));
          setEditMode(prev => ({ ...prev, [field]: false }));
          setHasChanges(prev => ({ ...prev, [field]: false }));
          setShowConfirmDialog(false);
        },
        field
      });
      setShowConfirmDialog(true);
    } else {
      setEditMode(prev => ({ ...prev, [field]: false }));
    }
  };

  const formatValueForDisplay = (field, value) => {
    // Handle message examples specially
    if (field === 'messageExamples') {
      return value.map(example => {
        const userMessage = example[0]?.content?.text || '';
        const agentMessage = example[1]?.content?.text || '';
        return `User: ${userMessage}\nAgent: ${agentMessage}`;
      }).join('\n\n');
    }

    // Handle settings object
    if (field === 'settings') {
      return Object.entries(value).map(([key, setting]) => {
        return `${key}:\n${Object.entries(setting).map(([k, v]) => `  ${k}: ${v}`).join('\n')}`;
      }).join('\n\n');
    }

    // Handle style object
    if (field === 'style') {
      return {
        all: Array.isArray(value.all) ? value.all.join('\n') : value.all,
        chat: Array.isArray(value.chat) ? value.chat.join('\n') : value.chat,
        post: Array.isArray(value.post) ? value.post.join('\n') : value.post
      };
    }

    // Handle array fields
    if (Array.isArray(value)) {
      return value.join('\n');
    }

    // Handle nested objects (like clientSettings)
    if (typeof value === 'object' && value !== null) {
      if (field === 'clientSettings') {
        return Object.entries(value)
          .map(([client, settings]) => {
            if (settings.enabled) {
              return `${client} (Enabled):\n` + 
                Object.entries(settings)
                  .filter(([key]) => key !== 'enabled')
                  .map(([key, val]) => val ? `${key}: ${val}` : null)
                  .filter(Boolean)
                  .join('\n');
            }
            return `${client} (Disabled)`;
          })
          .join('\n\n');
      }
    }
    
    return value;
  };

  const handleChange = (field, value) => {
    setHasChanges(prev => ({ ...prev, [field]: true }));
    
    if (field === 'messageExamples' || field === 'postExamples') {
      if (Array.isArray(value)) {
        setEditedValues(prev => ({
          ...prev,
          [field]: value
        }));
      } else {
        setEditedValues(prev => {
          const newValues = { ...prev };
          if (field === 'messageExamples') {
            const examples = value.split('\n\n').map(example => {
              const [userPart, agentPart] = example.split('\nAgent: ');
              const userMessage = userPart.replace('User: ', '');
              return [
                { user: '{{user1}}', content: { text: userMessage } },
                { user: prev.name || 'Agent', content: { text: agentPart } }
              ];
            });
            newValues[field] = examples;
          } else {
            newValues[field] = value.split('\n\n').filter(item => item.trim());
          }
          return newValues;
        });
      }
    } else if (field === 'settings') {
      setEditedValues(prev => ({
        ...prev,
        settings: value
      }));
    } else if (field.startsWith('style.')) {
      const [, styleField] = field.split('.');
      setEditedValues(prev => ({
        ...prev,
        style: {
          ...prev.style,
          [styleField]: value.split('\n').filter(item => item.trim())
        }
      }));
    } else if (['lore', 'adjectives', 'topics', 'knowledge'].includes(field)) {
      setEditedValues(prev => ({
        ...prev,
        [field]: value.split('\n').filter(item => item.trim())
      }));
    } else if (field === 'clientSettings') {
      setEditedValues(prev => ({
        ...prev,
        clientSettings: value
      }));
    } else if (field === 'status') {
      setEditedValues(prev => ({
        ...prev,
        status: value
      }));
    } else {
      setEditedValues(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleSubmitChanges = async () => {
    setLoading(true);
    try {
      const payload = { character_json: JSON.stringify(editedValues) };
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/agents/${id}/`,
        payload,
        {
          headers: {
            'uid': user?.id,
            'Content-Type': 'application/json',
            'SameSite': 'Strict',
            'Secure': true
          },
          withCredentials: true
        }
      );
      navigate('/agents/dashboard');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to save changes');
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen" role="alert">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-blue-600/20 backdrop-blur-sm rounded-lg p-8 text-center"
        >
          <div className="text-2xl font-bold text-white mb-2">Please connect your wallet</div>
        </motion.div>
      </div>
    );
  }

  if (error) {
    console.log('Rendering error state:', error);
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        {/* Backdrop */}
        <div className="absolute inset-0 bg-black/70 backdrop-blur-sm"></div>
        
        {/* Error Dialog */}
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="relative z-10 bg-gray-800/90 backdrop-blur-lg border border-red-500/30 rounded-lg p-8 text-center max-w-md mx-4 shadow-2xl"
        >
          <div className="absolute top-4 right-4">
            <button
              onClick={() => navigate('/agents/dashboard')}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="text-2xl font-bold text-red-500 mb-2">Error Loading Agent</div>
          <div className="text-gray-300 mb-6">{error}</div>
          <button
            onClick={() => navigate('/agents/dashboard')}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition-colors"
          >
            Return to Dashboard
          </button>
        </motion.div>
      </div>
    );
  }

  if (loading) {
    console.log('Rendering loading state...');
    return (
      <div className="flex items-center justify-center min-h-screen pt-20">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-black/50 backdrop-blur-sm rounded-lg p-8 text-center"
        >
          <div className="text-2xl font-bold text-white mb-2">Loading agent details...</div>
          <div className="text-gray-300">Please wait while we fetch your agent's information</div>
        </motion.div>
      </div>
    );
  }

  if (!agent) {
    console.log('No agent data available');
    return (
      <div className="flex items-center justify-center min-h-screen pt-20">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-red-500/10 backdrop-blur-sm rounded-lg p-8 text-center max-w-md mx-auto"
        >
          <div className="text-2xl font-bold text-red-500 mb-2">No Agent Found</div>
          <div className="text-red-400 mb-4">Could not find the requested agent.</div>
          <button
            onClick={() => navigate('/agents/dashboard')}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Return to Dashboard
          </button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center p-6 pt-24" role="main">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="fixed top-0 left-0 right-0 w-full h-full -z-10 overflow-hidden"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
          aria-hidden="true"
        >
          <source src="/videos/apollo_create.webm" type="video/mp4" />
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900/30"></div>
      </motion.div>

      <motion.form 
        className="w-full max-w-4xl bg-gray-800/60 backdrop-blur-sm rounded-lg shadow p-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitChanges();
        }}
      >
        <h1 className="text-2xl font-bold text-white mb-6">Edit Agent</h1>

        {agent && getFieldOrder(agent).map(([field, value]) => {
          // Skip certain fields
          if (['id', 'privyId', 'clients'].includes(field)) return null;

          const displayValue = formatValueForDisplay(field, value);

          return (
            <div key={field} className="mb-6">
              {/* Field Label Section */}
              <div className="flex justify-between items-center mb-3">
                <label className="text-gray-300 font-medium capitalize text-lg">
                  {field.replace(/([A-Z])/g, ' $1').trim()}
                </label>
                <div className="flex gap-2">
                  {editMode[field] ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleCancelEdit(field)}
                        className="px-4 py-1.5 rounded-md text-white text-sm transition-colors bg-gray-600 hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSave(field)}
                        className="px-4 py-1.5 rounded-md text-white text-sm transition-colors bg-green-600 hover:bg-green-500"
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleEdit(field)}
                      className="px-4 py-1.5 rounded-md text-white text-sm transition-colors bg-black/60 hover:bg-black/40"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>

              {/* Field Content Section */}
              {editMode[field] ? (
                field === 'style' ? (
                  <div className="space-y-4 p-4 bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 shadow-lg">
                    <div>
                      <label className="block text-gray-400 mb-1">General Style</label>
                      <textarea
                        className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                        value={editedValues.style?.all?.join('\n') || ''}
                        onChange={(e) => handleChange('style.all', e.target.value)}
                        rows={4}
                      />
                    </div>
                    <div>
                      <label className="block text-gray-400 mb-1">Chat Style</label>
                      <textarea
                        className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                        value={editedValues.style?.chat?.join('\n') || ''}
                        onChange={(e) => handleChange('style.chat', e.target.value)}
                        rows={4}
                      />
                    </div>
                    <div>
                      <label className="block text-gray-400 mb-1">Post Style</label>
                      <textarea
                        className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                        value={editedValues.style?.post?.join('\n') || ''}
                        onChange={(e) => handleChange('style.post', e.target.value)}
                        rows={4}
                      />
                    </div>
                  </div>
                ) : field === 'messageExamples' ? (
                  <div className="space-y-4">
                    {editedValues.messageExamples.map((example, index) => (
                      <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                        <div className="mb-3">
                          <div className="text-blue-400 font-medium mb-2">User Message:</div>
                          <textarea
                            className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                            value={example[0]?.content?.text || ''}
                            onChange={(e) => {
                              const newExamples = [...editedValues.messageExamples];
                              if (!newExamples[index]) {
                                newExamples[index] = [
                                  { user: '{{user1}}', content: { text: '' } },
                                  { user: editedValues.name || 'Agent', content: { text: '' } }
                                ];
                              }
                              if (!newExamples[index][0]) {
                                newExamples[index][0] = { user: '{{user1}}', content: { text: '' } };
                              }
                              newExamples[index][0].content.text = e.target.value;
                              setEditedValues(prev => ({
                                ...prev,
                                messageExamples: newExamples
                              }));
                              setHasChanges(prev => ({ ...prev, [field]: true }));
                            }}
                            rows={3}
                          />
                        </div>
                        <div>
                          <div className="text-green-400 font-medium mb-2">Agent Response:</div>
                          <textarea
                            className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                            value={example[1]?.content?.text || ''}
                            onChange={(e) => {
                              const newExamples = [...editedValues.messageExamples];
                              if (!newExamples[index]) {
                                newExamples[index] = [
                                  { user: '{{user1}}', content: { text: '' } },
                                  { user: editedValues.name || 'Agent', content: { text: '' } }
                                ];
                              }
                              if (!newExamples[index][1]) {
                                newExamples[index][1] = { user: editedValues.name || 'Agent', content: { text: '' } };
                              }
                              newExamples[index][1].content.text = e.target.value;
                              setEditedValues(prev => ({
                                ...prev,
                                messageExamples: newExamples
                              }));
                              setHasChanges(prev => ({ ...prev, [field]: true }));
                            }}
                            rows={3}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => {
                        const newExamples = [...editedValues.messageExamples];
                        newExamples.push([
                          { user: '{{user1}}', content: { text: '' } },
                          { user: editedValues.name || 'Agent', content: { text: '' } }
                        ]);
                        setEditedValues(prev => ({
                          ...prev,
                          messageExamples: newExamples
                        }));
                        setHasChanges(prev => ({ ...prev, messageExamples: true }));
                      }}
                      className="text-blue-400 hover:text-blue-300 transition-colors"
                    >
                      + Add Example
                    </button>
                  </div>
                ) : field === 'postExamples' ? (
                  <div className="space-y-4">
                    {editedValues.postExamples.map((example, index) => (
                      <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                        <div className="mb-2">
                          <div className="text-blue-400 font-medium mb-2">Post Example {index + 1}:</div>
                          <textarea
                            className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                            value={example}
                            onChange={(e) => {
                              const newExamples = [...editedValues.postExamples];
                              newExamples[index] = e.target.value;
                              handleChange('postExamples', newExamples);
                            }}
                            rows={3}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => {
                        const newExamples = [...editedValues.postExamples];
                        newExamples.push('');
                        handleChange('postExamples', newExamples);
                      }}
                      className="text-blue-400 hover:text-blue-300 transition-colors"
                    >
                      + Add Post Example
                    </button>
                  </div>
                ) : field === 'clientSettings' ? (
                  <div className="space-y-4">
                    {Object.entries(editedValues.clientSettings).map(([client, settings]) => (
                      <div key={client} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                        <div className="flex items-center justify-between mb-3">
                          <div className="text-lg font-medium">
                            {client.charAt(0).toUpperCase() + client.slice(1)}
                          </div>
                          <label className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              checked={settings.enabled}
                              onChange={() => {
                                const newSettings = { ...editedValues.clientSettings };
                                newSettings[client].enabled = !settings.enabled;
                                handleChange('clientSettings', newSettings);
                              }}
                              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <span className="text-gray-300">Enabled</span>
                          </label>
                        </div>
                        {settings.enabled && (
                          <div className="space-y-3">
                            {client === 'telegram' ? (
                              <div>
                                <label className="block text-gray-400 mb-1">Bot Token</label>
                                <input
                                  type="text"
                                  className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                                  value={settings.botToken || ''}
                                  onChange={(e) => {
                                    const newSettings = { ...editedValues.clientSettings };
                                    newSettings[client].botToken = e.target.value;
                                    handleChange('clientSettings', newSettings);
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                <div>
                                  <label className="block text-gray-400 mb-1">API Token</label>
                                  <input
                                    type="text"
                                    className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                                    value={settings.apiToken || ''}
                                    onChange={(e) => {
                                      const newSettings = { ...editedValues.clientSettings };
                                      newSettings[client].apiToken = e.target.value;
                                      handleChange('clientSettings', newSettings);
                                    }}
                                  />
                                </div>
                                {/* Add other client-specific fields here */}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : field === 'modelProvider' ? (
                  <select
                    className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                    value={editedValues[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                  >
                    <option value="openrouter">OpenRouter</option>
                    <option value="anthropic">Anthropic</option>
                  </select>
                ) : field === 'settings' ? (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-gray-400 mb-1">Voice Settings</label>
                      <div className="space-y-2">
                        <div>
                          <label className="block text-sm text-gray-400 mb-1">Model</label>
                          <select
                            className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                            value={editedValues.settings?.voice?.model || 'en_GB-danny-low'}
                            onChange={(e) => {
                              const newSettings = {
                                ...editedValues.settings,
                                voice: { ...editedValues.settings?.voice, model: e.target.value }
                              };
                              handleChange('settings', newSettings);
                            }}
                          >
                            <option value="en_GB-danny-low">English GB - Danny (Low)</option>
                            <option value="en_GB-danny-medium">English GB - Danny (Medium)</option>
                            <option value="en_GB-danny-high">English GB - Danny (High)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : field === 'plugins' ? (
                  <div className="space-y-4">
                    {/* Add plugin configuration UI here */}
                    <div className="text-gray-400 italic">No plugins configured</div>
                  </div>
                ) : field === 'knowledge' ? (
                  <div className="space-y-4">
                    {editedValues.knowledge.map((item, index) => (
                      <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                        <div className="mb-2">
                          <div className="text-blue-400 font-medium mb-2">Knowledge Item {index + 1}:</div>
                          <textarea
                            className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                            value={item}
                            onChange={(e) => {
                              const newKnowledge = [...editedValues.knowledge];
                              newKnowledge[index] = e.target.value;
                              handleChange('knowledge', newKnowledge.join('\n'));
                            }}
                            rows={3}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => {
                        const newKnowledge = [...editedValues.knowledge, ''];
                        handleChange('knowledge', newKnowledge.join('\n'));
                      }}
                      className="text-blue-400 hover:text-blue-300 transition-colors"
                    >
                      + Add Knowledge Item
                    </button>
                  </div>
                ) : field === 'status' ? (
                  <select
                    className="w-full bg-gray-700/50 rounded-md p-3 text-white"
                    value={editedValues[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                  >
                    <option value="running">Running</option>
                    <option value="stopped">Stopped</option>
                  </select>
                ) : (
                  <textarea
                    className="w-full bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4 text-white"
                    value={editedValues[field] || ''}
                    onChange={(e) => handleChange(field, e.target.value)}
                    rows={4}
                  />
                )
              ) : (
                // Display mode - different styling for simple vs complex fields
                ['messageExamples', 'style', 'clientSettings', 'postExamples'].includes(field) ? (
                  <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 shadow-lg p-4">
                    {field === 'postExamples' ? (
                      <div className="space-y-4">
                        {value.map((example, index) => (
                          <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                            <div className="text-blue-400 font-medium mb-2">Post Example {index + 1}:</div>
                            <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                              {example}
                            </div>
                          </div>
                        ))}
                        {value.length === 0 && (
                          <span className="text-gray-500 italic">No defined post examples</span>
                        )}
                      </div>
                    ) : field === 'messageExamples' ? (
                      <div className="space-y-4">
                        {displayValue.split('\n\n').map((example, index) => {
                          const [userPart, agentPart] = example.split('\nAgent: ');
                          return (
                            <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                              <div className="mb-3">
                                <div className="text-blue-400 font-medium mb-2">User Message:</div>
                                <div className="pl-4 bg-gray-800/50 rounded-md p-3 border border-gray-700">
                                  {userPart.replace('User: ', '')}
                                </div>
                              </div>
                              <div>
                                <div className="text-green-400 font-medium mb-2">Agent Response:</div>
                                <div className="pl-4 bg-gray-800/50 rounded-md p-3 border border-gray-700">
                                  {agentPart}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : field === 'style' ? (
                      <div className="space-y-4">
                        <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                          <div className="text-blue-400 font-medium mb-2">General Style:</div>
                          <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                            {displayValue.all.split('\n').map((item, index) => (
                              <div key={index} className="mb-1">{item}</div>
                            ))}
                          </div>
                        </div>
                        <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                          <div className="text-green-400 font-medium mb-2">Chat Style:</div>
                          <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                            {displayValue.chat.split('\n').map((item, index) => (
                              <div key={index} className="mb-1">{item}</div>
                            ))}
                          </div>
                        </div>
                        <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                          <div className="text-purple-400 font-medium mb-2">Post Style:</div>
                          <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                            {displayValue.post.split('\n').map((item, index) => (
                              <div key={index} className="mb-1">{item}</div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : field === 'clientSettings' ? (
                      <div className="grid grid-cols-1 gap-4">
                        {Object.entries(value).map(([client, settings]) => (
                          <div 
                            key={client}
                            className={`bg-gray-900/50 rounded-lg p-4 border ${
                              settings.enabled ? 'border-green-500/30' : 'border-gray-500/30'
                            }`}
                          >
                            <div className="text-lg mb-2 font-medium">
                              <span className={settings.enabled ? 'text-green-400' : 'text-gray-500'}>
                                {client.charAt(0).toUpperCase() + client.slice(1)}
                              </span>
                              <span className="text-sm ml-2">
                                ({settings.enabled ? 'Enabled' : 'Disabled'})
                              </span>
                            </div>
                            <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                                <div className="flex items-center">
                                  <span className="text-gray-400">Token:</span>{' '}
                                  <span className="text-gray-200 ml-2 break-all">
                                    {settings.enabled ? (
                                      <>
                                        {/* Show full token on desktop, truncated on mobile */}
                                        <span className="hidden sm:inline">
                                          {settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`] || 'No token set'}
                                        </span>
                                        <span className="inline sm:hidden">
                                          {settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`]?.length > 20 
                                            ? `${settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`].substring(0, 20)}...` 
                                            : settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`] || 'No token set'}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="text-gray-500 italic">Disabled</span>
                                    )}
                                  </span>
                                </div>
                                {settings.enabled && settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`] && (
                                  <button
                                    onClick={() => {
                                      navigator.clipboard.writeText(settings[`${client === 'telegram' ? 'botToken' : 'apiToken'}`]);
                                    }}
                                    className="px-3 py-1 bg-blue-600/20 hover:bg-blue-600/30 
                                             text-blue-400 rounded-md text-sm transition-colors
                                             flex items-center gap-2 whitespace-nowrap"
                                  >
                                    <svg 
                                      xmlns="http://www.w3.org/2000/svg" 
                                      className="h-4 w-4" 
                                      fill="none" 
                                      viewBox="0 0 24 24" 
                                      stroke="currentColor"
                                    >
                                      <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" 
                                      />
                                    </svg>
                                    Copy
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : field === 'modelProvider' ? (
                      <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4 text-white">
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                      </div>
                    ) : field === 'settings' ? (
                      <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4">
                        <div className="space-y-2">
                          <div className="text-blue-400 font-medium">Voice Settings:</div>
                          <div className="pl-4">
                            <span className="text-gray-400">Model: </span>
                            <span className="text-white">{value.voice?.model || 'Not set'}</span>
                          </div>
                        </div>
                      </div>
                    ) : field === 'plugins' ? (
                      <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4">
                        {Array.isArray(value) && value.length > 0 ? (
                          value.map((plugin, index) => (
                            <div key={index} className="text-white">{plugin}</div>
                          ))
                        ) : (
                          <span className="text-gray-500 italic">No plugins configured</span>
                        )}
                      </div>
                    ) : field === 'knowledge' ? (
                      <div className="space-y-4">
                        {value.map((item, index) => (
                          <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                            <div className="text-blue-400 font-medium mb-2">Knowledge Item {index + 1}:</div>
                            <div className="bg-gray-800/50 rounded-md p-3 border border-gray-700">
                              {item}
                            </div>
                          </div>
                        ))}
                        {value.length === 0 && (
                          <span className="text-gray-500 italic">No defined knowledge items</span>
                        )}
                      </div>
                    ) : field === 'status' ? (
                      <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4 text-white">
                        <span className={`${value === 'running' ? 'text-green-400' : 'text-red-400'}`}>
                          {value.charAt(0).toUpperCase() + value.slice(1)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  // Simple fields get a cleaner, single-box treatment
                  <div className="bg-gray-800/80 backdrop-blur-sm rounded-lg border border-gray-700 p-4 text-white">
                    {typeof displayValue === 'string' ? (
                      displayValue.trim() === '' ? (
                        <span className="text-gray-500 italic">No defined {field.toLowerCase()}</span>
                      ) : (
                        displayValue
                      )
                    ) : Array.isArray(displayValue) ? (
                      displayValue.length === 0 ? (
                        <span className="text-gray-500 italic">No defined {field.toLowerCase()}</span>
                      ) : (
                        displayValue.join(', ')
                      )
                    ) : (
                      JSON.stringify(displayValue, null, 2)
                    )}
                  </div>
                )
              )}
            </div>
          );
        })}

        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={() => navigate('/agents/dashboard')}
            className="px-4 py-2 bg-gray-600 rounded text-white hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 rounded text-white hover:bg-blue-500"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save All Changes'}
          </button>
        </div>
      </motion.form>

      <div className="fixed bottom-4 right-4 bg-black/50 p-2 rounded text-xs text-white">
        Agent ID: {id}
      </div>

      <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={confirmDialogConfig.onConfirm}
        title={confirmDialogConfig.title}
        message={confirmDialogConfig.message}
      />
    </div>
  );
};