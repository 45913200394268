import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useWallet } from '../../hooks/useWallet';
import { Header } from './Header';
import { CreateAgent } from './CreateAgent';
import { Dashboard } from './Dashboard';
import { Documentation } from './Documentation';
import { EditAgent } from './EditAgent';
import { motion } from 'framer-motion';

export const Agents = () => {
  const { walletAddress, isConnecting, connectWallet, disconnectWallet } = useWallet();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const MainContent = ({ isMobile }) => (
    <>
      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 1 }}
        className="text-center py-16 px-4 pt-24"
      >
      </motion.section>

      {/* Features Grid */}
      {!isMobile && (
        <motion.section 
          className="py-12 px-12 h-[60vh] flex items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2 }}
        >
          <motion.div 
            className="container mx-auto flex justify-between max-w-[1400px]"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.2
                }
              }
            }}
            initial="hidden"
            animate="show"
          >
            {/* Left Column */}
            <div className="w-1/4">
              <motion.div
                variants={{
                  hidden: { opacity: 0, x: -20 },
                  show: { opacity: 1, x: 0 }
                }}
                className="bg-gray-800/60 backdrop-blur-sm p-6 rounded-lg shadow-lg text-center"
              >
                <h3 className="text-xl font-bold text-white mb-2">Social Media Integration</h3>
                <p className="text-gray-200 italic">X, Telegram, Discord</p>
              </motion.div>
            </div>

            {/* Center space for logo */}
            <div className="w-2/4"></div>

            {/* Right Column */}
            <div className="w-1/4">
              <motion.div
                variants={{
                  hidden: { opacity: 0, x: 20 },
                  show: { opacity: 1, x: 0 }
                }}
                className="bg-gray-800/60 backdrop-blur-sm p-6 rounded-lg shadow-lg text-center"
              >
                <h3 className="text-xl font-bold text-white mb-2">Code-Free</h3>
                <p className="text-gray-200 italic">Build AI agents without writing code</p>
              </motion.div>
            </div>
          </motion.div>
        </motion.section>
      )}

      {/* Dexscreener Logo */}
      <div className="fixed bottom-4 left-0 right-0 flex justify-center">
        <a
          href="https://dexscreener.com/solana/b5rg1mxjtofbsttmyavhmox1tx4wvcdm1nykrfz9cnfi"
          target="_blank"
          rel="noopener noreferrer"
          className="transform hover:scale-110 transition-all duration-200 ease-in-out"
        >
          <img 
            src="/images/dex2.png" 
            alt="Dexscreener" 
            className="h-16 w-auto"
          />
        </a>
      </div>
    </>
  );

  return (
    <div className="relative min-h-screen text-gray-100 overflow-x-hidden">
      {/* Background */}
      {isMobile ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, ease: "easeOut" }}
          className="fixed top-0 left-0 w-full h-full"
          style={{ 
            backgroundImage: 'url("https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/space2.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, ease: "easeOut" }}
          className="fixed top-0 left-0 w-full h-full"
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="w-full h-full object-cover"
          >
            <source src="/videos/apollo_agents2.mp4" type="video/mp4" />
          </video>
        </motion.div>
      )}

      <div className="relative z-10">
        <Header 
          walletAddress={walletAddress}
          isConnecting={isConnecting}
          connectWallet={connectWallet}
          disconnectWallet={disconnectWallet}
        />
        
        <Routes>
          <Route path="/" element={<MainContent isMobile={isMobile} />} />
          <Route path="/create" element={<CreateAgent />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/edit/:id" element={<EditAgent />} />
        </Routes>
      </div>
    </div>
  );
}; 