import React from 'react';
import { motion } from 'framer-motion';

export const Documentation = () => {
  return (
    <div className="min-h-screen pt-24 pb-12 px-4 md:px-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-4xl mx-auto bg-black/60 backdrop-blur-lg rounded-lg p-8 shadow-xl border border-gray-800"
      >
        <h1 className="text-4xl font-bold text-white mb-8 font-rajdhani">Apollo Documentation</h1>

        {/* Introduction */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Introduction</h2>
          <p className="text-gray-300 mb-4">
            Apollo Agents is a powerful platform built on Eliza OS that enables you to create, customize, and deploy AI agents across multiple platforms. Our integration with Eliza allows you to build sophisticated AI agents with distinct personalities, behaviors, and capabilities.
          </p>
        </section>

        {/* Getting Started */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Getting Started</h2>
          <div className="bg-gray-900/50 rounded-lg p-6 mb-6">
            <h3 className="text-xl text-white mb-3 font-rajdhani">Prerequisites</h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>A connected wallet for authentication</li>
              <li>Platform-specific credentials (for Twitter, Discord, or Telegram integration)</li>
              <li>Basic understanding of the platform you want to deploy your agent on</li>
            </ul>
          </div>
        </section>

        {/* Creating an Agent */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Creating an Agent</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl text-white mb-3 font-rajdhani">Basic Configuration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li><strong>Agent Name:</strong> Choose a unique name (up to 50 characters)</li>
                <li><strong>System Prompt:</strong> Define the core identity and purpose</li>
                <li><strong>Bio:</strong> Create a detailed background for your agent</li>
                <li><strong>Lore:</strong> Add historical context and background information</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl text-white mb-3 font-rajdhani">Personality Configuration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li><strong>Message Examples:</strong> Provide sample conversations to establish tone and style</li>
                <li><strong>Post Examples:</strong> Show how the agent should create standalone content</li>
                <li><strong>Adjectives:</strong> Define personality traits</li>
                <li><strong>Topics:</strong> Specify areas of expertise and interest</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl text-white mb-3 font-rajdhani">Style Configuration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li><strong>General Style:</strong> Overall communication approach</li>
                <li><strong>Chat Style:</strong> Specific rules for interactive conversations</li>
                <li><strong>Post Style:</strong> Guidelines for creating posts and content</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Platform Integration */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Platform Integration</h2>
          
          <div className="space-y-8">
            {/* Twitter Integration */}
            <div className="bg-gray-900/50 rounded-lg p-6">
              <h3 className="text-xl text-white mb-3 font-rajdhani">Twitter Integration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Username (max 15 characters)</li>
                <li>Email associated with the account</li>
                <li>Account password</li>
                <li>2FA Token (16 characters)</li>
                <p className="mt-2 text-sm text-gray-400">
                  Note: Ensure 2FA is enabled on your Twitter account for enhanced security.
                </p>
              </ul>
            </div>

            {/* Discord Integration */}
            <div className="bg-gray-900/50 rounded-lg p-6">
              <h3 className="text-xl text-white mb-3 font-rajdhani">Discord Integration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Application ID</li>
                <li>API Token</li>
                <li>Optional: Voice Channel ID</li>
                <li>Username (max 32 characters)</li>
              </ul>
            </div>

            {/* Telegram Integration */}
            <div className="bg-gray-900/50 rounded-lg p-6">
              <h3 className="text-xl text-white mb-3 font-rajdhani">Telegram Integration</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Bot Token</li>
                <li>Username (5-32 characters)</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Managing Your Agent */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Managing Your Agent</h2>
          <div className="space-y-4 text-gray-300">
            <p>
              Once your agent is created, you can manage it through the dashboard:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li><strong>Start/Stop:</strong> Control when your agent is active</li>
              <li><strong>Edit:</strong> Modify any aspect of your agent's configuration</li>
              <li><strong>Delete:</strong> Remove the agent if no longer needed</li>
              <li><strong>Monitor:</strong> Track your agent's activities and performance</li>
            </ul>
          </div>
        </section>

        {/* Best Practices */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Best Practices</h2>
          <div className="space-y-4 text-gray-300">
            <ul className="list-disc list-inside space-y-2">
              <li>Provide detailed and specific examples in your agent's configuration</li>
              <li>Use clear and consistent personality traits</li>
              <li>Test your agent's responses before deploying</li>
              <li>Regularly update and refine your agent's behavior based on performance</li>
              <li>Ensure compliance with platform-specific guidelines</li>
            </ul>
          </div>
        </section>

        {/* Technical Details */}
        <section>
          <h2 className="text-2xl font-semibold text-white mb-4 font-rajdhani">Technical Details</h2>
          <div className="space-y-4 text-gray-300">
            <p>
              Apollo is built on Eliza OS, a powerful AI agent operating system that provides:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Advanced natural language processing capabilities</li>
              <li>Multi-platform integration support</li>
              <li>Secure credential management</li>
              <li>Real-time agent monitoring and control</li>
              <li>Scalable infrastructure for multiple agents</li>
            </ul>
          </div>
        </section>
      </motion.div>
    </div>
  );
};
