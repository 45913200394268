// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrivyProvider } from '@privy-io/react-auth';
import { Official } from './components/Official';
import { Agents } from './components/agents/Agents';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error('App Error:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-black text-white flex items-center justify-center">
          <div className="text-center p-4">
            <h1 className="text-2xl mb-4">Something went wrong</h1>
            <pre className="text-red-500">{this.state.error?.message}</pre>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Update PrivyWrappedAgents to handle nested routes correctly
const PrivyWrappedAgents = () => (
  <PrivyProvider
    appId={process.env.REACT_APP_PRIVY_APP_ID}
    config={{
      loginMethods: ['wallet'],
      appearance: {
        theme: 'dark',
        accentColor: '#3B82F6',
        showWalletLoginFirst: true,
      },
      supportedWallets: ['phantom', 'solflare'],
      embeddedWallets: {
        createOnLogin: 'users-choice',
        noPromptOnSignature: false,
      },
    }}
  >
    <Routes>
      <Route path="/*" element={<Agents />} />
    </Routes>
  </PrivyProvider>
);

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-black">
          <Routes>
            <Route path="/" element={<Official />} />
            <Route path="/agents/*" element={<PrivyWrappedAgents />} />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;