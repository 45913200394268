import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const CreateAgent = () => {
  const navigate = useNavigate();
  const { user, login } = usePrivy();
  const [showClientSettings, setShowClientSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    privyId: user?.id || '',
    name: '',
    system: '',
    bio: '',
    lore: [],
    messageExamples: [],
    postExamples: [],
    adjectives: [],
    topics: [],
    status: 'running',
    style: {
      all: [],
      chat: [],
      post: []
    },
    modelProvider: 'openrouter',
    plugins: [],
    clientSettings: {
      twitter: {
        enabled: false,
        username: '',
        email: '',
        password: '',
        authToken: ''
      },
      discord: {
        enabled: false,
        applicationId: '',
        apiToken: '',
        voiceChannelId: ''
      },
      telegram: {
        enabled: false,
        botToken: ''
      }
    }
  });

  // Fetch existing agents
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/agents/`, {
          headers: {
            'uid': user?.id || ''
          }
        });
        if (response.data.length === 0) {
          console.log('No agents found - this is normal for new users');
        }
      } catch (err) {
        // Only log the error, don't show it to the user since it's not critical for agent creation
        console.log('Note: Could not fetch existing agents - this is normal for new users');
      }
    };

    if (user?.id) {  // Only try to fetch if we have a user ID
      fetchAgents();
    }
  }, [user?.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update name length restriction to 50
    if (name === 'name' && value.length > 50) return;
    
    // Handle array fields
    if (['lore', 'postExamples', 'adjectives', 'topics'].includes(name)) {
      setFormData(prev => ({
        ...prev,
        [name]: value.split('\n').filter(item => item.trim() !== '') // Split by newlines and remove empty lines
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleStyleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      style: {
        ...prev.style,
        [name]: value.split('\n').filter(item => item.trim() !== '') // Split by newlines and remove empty lines
      }
    }));
  };

  const addMessageExample = () => {
    setFormData(prev => ({
      ...prev,
      messageExamples: [
        ...prev.messageExamples,
        [
          {
            user: '{{user1}}',
            content: {
              text: '' // User message
            }
          },
          {
            user: prev.name || 'Agent',
            content: {
              text: '' // Agent response
            }
          }
        ]
      ]
    }));
  };

  const handleMessageExampleChange = (index, isUserMessage, value) => {
    setFormData(prev => {
      const newExamples = [...prev.messageExamples];
      if (!newExamples[index]) {
        newExamples[index] = [
          { user: '{{user1}}', content: { text: '' } },
          { user: prev.name || 'Agent', content: { text: '' } }
        ];
      }
      
      const messageIndex = isUserMessage ? 0 : 1;
      newExamples[index][messageIndex].content.text = value;
      
      return {
        ...prev,
        messageExamples: newExamples
      };
    });
  };

  const handleClientSettingToggle = (platform) => {
    setFormData(prev => ({
      ...prev,
      clientSettings: {
        ...prev.clientSettings,
        [platform]: {
          ...prev.clientSettings[platform],
          enabled: !prev.clientSettings[platform].enabled
        }
      }
    }));
  };

  const handleClientSettingInput = (platform, field, value) => {
    // Add validation for specific fields
    if (platform === 'twitter') {
      if (field === 'username' && value.length > 15) return; // Max 15 chars for Twitter username
      if (field === 'authToken') {
        // Only allow exactly 16 characters for 2FA token
        const sanitizedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        if (sanitizedValue.length > 16) return;
        value = sanitizedValue;
      }
    } else if (platform === 'discord') {
      if (field === 'username' && value.length > 32) return; // Max 32 chars for Discord username
    } else if (platform === 'telegram') {
      if (field === 'username') {
        if (value.length > 32 || (value.length < 5 && value.length > 0)) return; // Between 5-32 chars for Telegram
      }
    }

    setFormData(prev => ({
      ...prev,
      clientSettings: {
        ...prev.clientSettings,
        [platform]: {
          ...prev.clientSettings[platform],
          [field]: value
        }
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    // Validate agent name
    if (!formData.name) {
      setError('Agent name is required');
      setLoading(false);
      return;
    }

    if (formData.name.length > 50) {
      setError('Agent name must be 50 characters or less');
      setLoading(false);
      return;
    }

    // Validate client settings if enabled
    if (formData.clientSettings.twitter.enabled) {
      const { username, email, password, authToken } = formData.clientSettings.twitter;
      if (!username || !email || !password || !authToken) {
        setError('Please fill in all required Twitter fields');
        setLoading(false);
        return;
      }
      // Additional Twitter validations
      if (username.length > 15) {
        setError('Twitter username must be 15 characters or less');
        setLoading(false);
        return;
      }
      if (authToken.length !== 16) {
        setError('2FA token must be exactly 16 characters');
        setLoading(false);
        return;
      }
    }

    if (formData.clientSettings.discord.enabled) {
      const { applicationId, apiToken, username } = formData.clientSettings.discord;
      if (!applicationId || !apiToken) {
        setError('Please fill in all required Discord fields');
        setLoading(false);
        return;
      }
      // Additional Discord validation
      if (username && username.length > 32) {
        setError('Discord username must be 32 characters or less');
        setLoading(false);
        return;
      }
    }

    if (formData.clientSettings.telegram.enabled) {
      const { botToken, username } = formData.clientSettings.telegram;
      if (!botToken) {
        setError('Please fill in the Telegram bot token');
        setLoading(false);
        return;
      }
      // Additional Telegram validation
      if (username && (username.length < 5 || username.length > 32)) {
        setError('Telegram username must be between 5 and 32 characters');
        setLoading(false);
        return;
      }
    }

    try {
      // Create a copy of formData to transform
      const transformedData = {
        ...formData,
        // Ensure arrays are properly formatted
        lore: Array.isArray(formData.lore) ? formData.lore : formData.lore.split('\n').filter(Boolean),
        postExamples: Array.isArray(formData.postExamples) ? formData.postExamples : formData.postExamples.split('\n').filter(Boolean),
        adjectives: Array.isArray(formData.adjectives) ? formData.adjectives : formData.adjectives.split('\n').filter(Boolean),
        topics: Array.isArray(formData.topics) ? formData.topics : formData.topics.split('\n').filter(Boolean),
        plugins: [], // Ensure plugins is always an array
        style: {
          all: Array.isArray(formData.style.all) ? formData.style.all : formData.style.all.split('\n').filter(Boolean),
          chat: Array.isArray(formData.style.chat) ? formData.style.chat : formData.style.chat.split('\n').filter(Boolean),
          post: Array.isArray(formData.style.post) ? formData.style.post : formData.style.post.split('\n').filter(Boolean),
        },
        // Ensure messageExamples is properly structured
        messageExamples: formData.messageExamples.map(example => [
          {
            user: '{{user1}}',
            content: { text: example[0]?.content?.text || '' }
          },
          {
            user: formData.name,
            content: { text: example[1]?.content?.text || '' }
          }
        ])
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agents/`, transformedData, {
        headers: {
          'uid': user?.id || ''
        }
      });
      console.log('Agent created successfully:', response.data);
      setSuccess(true);
    } catch (err) {
      console.error('Error creating agent:', err);
      if (err.response?.status === 400) {
        setError('Invalid agent data. Please check all required fields.');
      } else if (err.response?.status === 401) {
        setError('Authentication required. Please login again.');
      } else if (err.response?.status === 403) {
        setError('You do not have permission to create agents.');
      } else {
        setError('Failed to create agent. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Success message component
  if (success) {
    return (
      <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
        {/* Background Image with fade-in */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ 
            duration: 1.2,
            ease: "easeOut"
          }}
          className="fixed top-0 left-0 right-0 w-[100vw] h-full -z-10 overflow-hidden"
        >
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ 
              duration: 1.5,
              ease: "easeOut"
            }}
            src="/images/space2.jpg"
            alt="Space background"
            className="w-full h-full object-cover"
          />
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="absolute top-0 left-0 w-full h-full bg-gray-900/30"
          />
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.8,
            delay: 0.3,
            ease: "easeOut"
          }}
          className="bg-gray-900/60 backdrop-blur-sm rounded-2xl p-12 shadow-2xl max-w-2xl w-full text-center border border-gray-700"
        >
          <div className="mb-4 text-green-400 text-5xl">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h2 className="text-3xl font-bold text-white mb-4">
            "{formData.name}" Agent Created Successfully!
          </h2>
          <p className="text-gray-300 text-lg mb-8">
            Your agent has been created and is ready to be managed.
          </p>
          <button
            onClick={() => navigate('/agents/dashboard')}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition-colors duration-200"
          >
            Go to Dashboard
          </button>
        </motion.div>
      </div>
    );
  }

  // If not authenticated, show connect wallet prompt
  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800 p-8 rounded-lg shadow-xl text-center"
        >
          <h2 className="text-2xl font-bold text-white mb-4">Connect Wallet to Create Agent</h2>
          <p className="text-gray-300 mb-6">Please connect your wallet to start creating your AI agent</p>
          <button
            onClick={login}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition-colors duration-200"
          >
            Connect Wallet
          </button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center p-6 pt-24">
      {/* Background Video */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 1,
          ease: "easeInOut"
        }}
        className="fixed top-0 left-0 right-0 w-[100vw] h-full -z-10 overflow-hidden"
      >
        <video
          id="create-background-video"
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
          style={{ transform: 'translate3d(0, 0, 0)' }}
        >
          <source src="/videos/apollo_create.webm" type="video/mp4" />
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900/30"></div>
      </motion.div>

      {/* Content */}
      <motion.header 
        className="w-full max-w-4xl bg-black/20 backdrop-blur-md rounded-lg p-6 mb-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <h1 className="text-3xl font-bold text-white mb-2">Create An Agent</h1>
        <p className="text-gray-300 mb-4">
          Need help? Check out our <button className="text-blue-400 underline hover:text-blue-300">documentation</button>
        </p>
        <div className="flex justify-between text-sm">
          <span className={`font-semibold px-4 py-2 rounded-lg transition-colors ${!showClientSettings ? 'bg-blue-500/20 text-blue-300' : 'text-gray-400'}`}>
            1. Agent Details
          </span>
          <span className={`px-4 py-2 rounded-lg transition-colors ${showClientSettings ? 'bg-blue-500/20 text-blue-300 font-semibold' : 'text-gray-400'}`}>
            2. Client Settings
          </span>
        </div>
      </motion.header>

      {error && (
        <div className="w-full max-w-4xl mt-4 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
          {error}
        </div>
      )}

      {!showClientSettings ? (
        <motion.form 
          onSubmit={handleSubmit}
          className="w-full max-w-4xl bg-gray-800 rounded-lg shadow p-6 mt-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          {/* Basic Info */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <label className="block font-medium">Agent Name*</label>
              <span className="text-xs text-gray-400">
                {formData.name.length}/50 characters
              </span>
            </div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
              placeholder="Enter agent name (max 50 characters)"
              required
              maxLength={50}
            />
          </div>

          {/* System Prompt */}
          <div className="mb-6">
            <label className="block font-medium mb-2">System Prompt</label>
            <textarea
              name="system"
              value={formData.system}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 h-24"
              placeholder="Describe the core identity and purpose of your AI agent..."
            />
          </div>

          {/* Bio */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Bio</label>
            <textarea
              name="bio"
              value={formData.bio}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 h-32"
              placeholder="E.g. C-3PO is a protocol droid fluent in over six million forms of communication."
            />
          </div>

          {/* Lore */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Lore</label>
            <textarea
              name="lore"
              value={formData.lore.join('\n')}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 h-32"
              placeholder="E.g. Built to serve human-cyborg relations, with expertise in etiquette, customs, and translation."
            />
          </div>

          {/* Message Examples */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Message Examples</label>
            {formData.messageExamples.map((example, index) => (
              <div key={index} className="mb-4">
                <input
                  type="text"
                  value={example[0]?.content?.text || ''}
                  onChange={(e) => handleMessageExampleChange(index, true, e.target.value)}
                  className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 mb-2"
                  placeholder="User message..."
                />
                <input
                  type="text"
                  value={example[1]?.content?.text || ''}
                  onChange={(e) => handleMessageExampleChange(index, false, e.target.value)}
                  className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="Agent response..."
                />
              </div>
            ))}
            <button
              type="button"
              onClick={addMessageExample}
              className="text-blue-500 underline mt-2"
            >
              Add Example
            </button>
          </div>

          {/* Post Examples */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Post Examples</label>
            <textarea
              name="postExamples"
              value={formData.postExamples.join('\n')}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 h-32"
              placeholder="E.g. Oh my! Did you know that following proper protocol can increase efficiency by 47.3%? How fascinating!"
            />
          </div>

          {/* Adjectives */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Adjectives</label>
            <textarea
              name="adjectives"
              value={formData.adjectives.join('\n')}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
              placeholder="E.g. Proper, Meticulous, Anxious, Diplomatic, Protocol-minded"
            />
          </div>

          {/* Topics */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Topics</label>
            <textarea
              name="topics"
              value={formData.topics.join('\n')}
              onChange={handleInputChange}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500 h-32"
              placeholder="Enter topics, one per line..."
            />
          </div>

          {/* Style */}
          <div className="mb-6">
            <label className="block font-medium mb-2">Style</label>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-400 mb-1">General Style</label>
                <textarea
                  name="all"
                  value={formData.style.all.join('\n')}
                  onChange={handleStyleChange}
                  className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="E.g. Proper, Formal, Slightly anxious, Detail-oriented, Protocol-focused"
                />
              </div>
              <div>
                <label className="block text-gray-400 mb-1">Chat Style</label>
                <textarea
                  name="chat"
                  value={formData.style.chat.join('\n')}
                  onChange={handleStyleChange}
                  className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="Enter chat-specific style rules, one per line..."
                />
              </div>
              <div>
                <label className="block text-gray-400 mb-1">Post Style</label>
                <textarea
                  name="post"
                  value={formData.style.post.join('\n')}
                  onChange={handleStyleChange}
                  className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="Enter post-specific style rules, one per line..."
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button 
              type="button"
              className="px-4 py-2 bg-blue-600 rounded text-white hover:bg-blue-500"
              onClick={() => setShowClientSettings(true)}
              disabled={loading}
            >
              Next Step
            </button>
          </div>
        </motion.form>
      ) : (
        <motion.form 
          onSubmit={handleSubmit}
          className="w-full max-w-4xl bg-gray-800 rounded-lg shadow p-6 mt-4"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4 }}
        >
          <h2 className="text-xl font-semibold mb-4">Client Settings</h2>

          {/* Twitter */}
          <div className="mb-6">
            <label className="flex items-center font-medium mb-2">
              <input 
                type="checkbox" 
                className="mr-2"
                checked={formData.clientSettings.twitter.enabled}
                onChange={() => handleClientSettingToggle('twitter')}
              /> 
              Twitter
            </label>
            <span className="text-gray-400 text-sm">Setup Guide</span>

            {formData.clientSettings.twitter.enabled && (
              <div className="mt-4 space-y-4 pl-6">
                <div>
                  <label className="block text-sm font-medium mb-1">Username*</label>
                  <input
                    type="text"
                    placeholder="Twitter username (without @) - max 15 characters"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.twitter.username}
                    onChange={(e) => handleClientSettingInput('twitter', 'username', e.target.value)}
                    required={formData.clientSettings.twitter.enabled}
                    maxLength={15}
                  />
                  <span className="text-xs text-gray-400 mt-1">
                    {formData.clientSettings.twitter.username.length}/15 characters
                  </span>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Email*</label>
                  <input
                    type="email"
                    placeholder="Twitter account email"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.twitter.email}
                    onChange={(e) => handleClientSettingInput('twitter', 'email', e.target.value)}
                    required={formData.clientSettings.twitter.enabled}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Password*</label>
                  <input
                    type="password"
                    placeholder="Twitter account password"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.twitter.password}
                    onChange={(e) => handleClientSettingInput('twitter', 'password', e.target.value)}
                    required={formData.clientSettings.twitter.enabled}
                  />
                </div>
                <div>
                  <div className="flex justify-between items-center mb-1">
                    <label className="block text-sm font-medium">2FA Token* (16 characters)</label>
                    <a 
                      href="https://help.x.com/en/managing-your-account/two-factor-authentication"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 text-sm transition-colors"
                    >
                      How do I Enable 2FA?
                    </a>
                  </div>
                  <input
                    type="text"
                    placeholder="1234ABCD5678EFGH"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.twitter.authToken}
                    onChange={(e) => handleClientSettingInput('twitter', 'authToken', e.target.value)}
                    required={formData.clientSettings.twitter.enabled}
                    maxLength={16}
                    pattern="[A-Za-z0-9]{16}"
                    title="2FA token must be exactly 16 alphanumeric characters"
                  />
                  <span className="text-xs text-gray-400 mt-1">
                    {formData.clientSettings.twitter.authToken.length}/16 characters
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Discord */}
          <div className="mb-6">
            <label className="flex items-center font-medium mb-2">
              <input 
                type="checkbox" 
                className="mr-2"
                checked={formData.clientSettings.discord.enabled}
                onChange={() => handleClientSettingToggle('discord')}
              /> 
              Discord
            </label>

            {formData.clientSettings.discord.enabled && (
              <div className="mt-4 space-y-4 pl-6">
                <div>
                  <label className="block text-sm font-medium mb-1">Application ID*</label>
                  <input
                    type="text"
                    placeholder="Discord Application ID"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.discord.applicationId}
                    onChange={(e) => handleClientSettingInput('discord', 'applicationId', e.target.value)}
                    required={formData.clientSettings.discord.enabled}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">API Token*</label>
                  <input
                    type="text"
                    placeholder="Discord Bot Token"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.discord.apiToken}
                    onChange={(e) => handleClientSettingInput('discord', 'apiToken', e.target.value)}
                    required={formData.clientSettings.discord.enabled}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Voice Channel ID (Optional)</label>
                  <input
                    type="text"
                    placeholder="Voice Channel ID"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.discord.voiceChannelId}
                    onChange={(e) => handleClientSettingInput('discord', 'voiceChannelId', e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Username (max 32 characters)</label>
                  <input
                    type="text"
                    placeholder="Discord username"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.discord.username || ''}
                    onChange={(e) => handleClientSettingInput('discord', 'username', e.target.value)}
                    maxLength={32}
                  />
                  <span className="text-xs text-gray-400 mt-1">
                    {(formData.clientSettings.discord.username || '').length}/32 characters
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Telegram */}
          <div className="mb-6">
            <label className="flex items-center font-medium mb-2">
              <input 
                type="checkbox" 
                className="mr-2"
                checked={formData.clientSettings.telegram.enabled}
                onChange={() => handleClientSettingToggle('telegram')}
              /> 
              Telegram
            </label>
            <span className="text-gray-400 text-sm">Setup Guide</span>

            {formData.clientSettings.telegram.enabled && (
              <div className="mt-4 space-y-4 pl-6">
                <div>
                  <label className="block text-sm font-medium mb-1">Bot Token*</label>
                  <input
                    type="text"
                    placeholder="Telegram Bot Token"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.telegram.botToken}
                    onChange={(e) => handleClientSettingInput('telegram', 'botToken', e.target.value)}
                    required={formData.clientSettings.telegram.enabled}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Username (5-32 characters)</label>
                  <input
                    type="text"
                    placeholder="Telegram username"
                    className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                    value={formData.clientSettings.telegram.username || ''}
                    onChange={(e) => handleClientSettingInput('telegram', 'username', e.target.value)}
                    minLength={5}
                    maxLength={32}
                  />
                  <span className="text-xs text-gray-400 mt-1">
                    {(formData.clientSettings.telegram.username || '').length}/32 characters
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between mt-6">
            <button 
              type="button"
              className="px-4 py-2 bg-gray-600 rounded text-white hover:bg-gray-500"
              onClick={() => setShowClientSettings(false)}
              disabled={loading}
            >
              Back
            </button>
            <button 
              type="submit"
              className={`px-4 py-2 bg-blue-600 rounded text-white hover:bg-blue-500 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
            >
              {loading ? 'Creating Agent...' : 'Create Agent'}
            </button>
          </div>
        </motion.form>
      )}
    </div>
  );
};
